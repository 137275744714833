<template>
  <div class="authoring-wrapper">
    <div class="flex h-full">
      <ProgressBar
        :stepIndex="stepIndex"
        :surveyID="surveyID"
        :stepsType="isPackSbdTestPresent ? 'pack-sbd' : 'pack'"
      />
      <section class="wrapper">
        <a-breadcrumb class="breadcrum breadcrum--authoring">
          <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
            <a-breadcrumb-item
              ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
            >
            <a-breadcrumb-item
              ><a href="/products">Products</a></a-breadcrumb-item
            >
            <a-breadcrumb-item>{{ breadcrumbTitle }}</a-breadcrumb-item>
          </template>
        </a-breadcrumb>
        <div class="wrapper__inner">
          <div id="objectivesWrapper">
            <div class="page-title page-title--transparent-svg-color">
              <icon-base
                :width="32"
                :height="33"
                :viewBox1="32"
                :viewBox2="33"
                iconName="bulb"
                iconStroke="#4318FF"
                ><icon-bulb
              /></icon-base>
              <div class="page-title__text">
                <h1>Brand Strategy</h1>
                <span>Emotional Attributes</span>
              </div>
            </div>
            <div class="objectives__left-col flex-1">
              <Loader
                text="Loading data..."
                :paddingTop="150"
                v-if="loading"
              ></Loader>
              <div v-else>
                <div
                  class="box-wrapper"
                  v-if="surveyInfo && surveyInfo.studyType !== 'SBD'"
                >
                  <h2>Communication Objective</h2>
                  <div class="section-info">
                    <p>
                      Based on your packaging objectives please choose the key
                      target equity associations you’re looking to reinforce. A
                      primary objective is required but this methodology
                      supports a secondary if applicable.
                    </p>
                  </div>
                  <div class="flex items-start objectives__list">
                    <div class="input-group">
                      <label
                        >Primary Communication Objective
                        <a-tooltip>
                          <template slot="title">
                            Communication objectives are the associations your
                            packaging should be communicating. For Implicit, we
                            will test how people unconsciously associate these
                            targets with your brand and packaging. For explicit,
                            it will help guide our analysis.
                          </template>
                          <a-icon type="info-circle" />
                        </a-tooltip>
                      </label>
                      <a-select
                        class="fullWidth"
                        option-filter-prop="children"
                        show-search
                        placeholder="Select communication objective"
                        @change="selectObjective1"
                        :key="Math.random()"
                        :default-value="
                          chosenCommunicationObjective1 !== 0
                            ? chosenCommunicationObjective1
                            : undefined
                        "
                        :class="{ dlb: dlbStatus }"
                      >
                        <a-select-option
                          v-for="item in communicationObjectives"
                          :key="item.CommunicationObjectiveID"
                          :value="item.CommunicationObjectiveID"
                        >
                          {{ item.CommunicationObjectiveName }}
                        </a-select-option>
                      </a-select>
                    </div>
                    <div
                      class="objectives__add-more"
                      @click="showCommunicationObjective2 = true"
                      v-if="
                        !showCommunicationObjective2 &&
                        chosenCommunicationObjective1 !== 0 &&
                        !dlbStatus
                      "
                    >
                      + <span>Add second communication objective</span>
                    </div>
                    <div class="input-group" v-if="showCommunicationObjective2">
                      <label
                        >Second Communication Objective
                        <span class="flex items-center gap-15">
                          <div
                            class="objectives__select-remove"
                            @click="removeCommunicationObjective"
                          >
                            <icon-base
                              :width="24"
                              :height="24"
                              :viewBox1="24"
                              :viewBox2="24"
                              iconName="trash"
                              iconStroke="#353F44"
                              ><icon-trash
                            /></icon-base>
                          </div>
                        </span>
                      </label>
                      <a-select
                        class="fullWidth"
                        option-filter-prop="children"
                        show-search
                        placeholder="Select communication objective"
                        :key="Math.random()"
                        :default-value="
                          chosenCommunicationObjective2 !== 0
                            ? chosenCommunicationObjective2
                            : undefined
                        "
                        @change="selectObjective2"
                        :class="{ dlb: dlbStatus }"
                      >
                        <a-select-option
                          v-for="item in filteredCommunicationObjectives"
                          :key="item.CommunicationObjectiveID"
                          :value="item.CommunicationObjectiveID"
                        >
                          {{ item.CommunicationObjectiveName }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>

                <div
                  class="box-wrapper"
                  v-if="surveyInfo && surveyInfo.studyType !== 'SBD'"
                >
                  <div class="overflow-hidden objectives__form-attributes">
                    <div class="flex-1" id="emotionalAttributesContent">
                      <div class="objectives__select objectives__select--full">
                        <div class="section-info">
                          <p>
                            Please select
                            <span class="red">3-9 attributes</span> to reflect
                            the way you would like your packaging to convey the
                            concepts of
                            {{ selectedCO1Name }}
                            <span v-if="selectedCO2Name !== null">
                              and {{ selectedCO2Name }}</span
                            >.
                          </p>
                        </div>
                        <div class="objectives__form-attributes-number">
                          <p class="color-blue-dark font-size-14 font-w-500">
                            {{ chosenAttributes.length }} selected attributes
                          </p>
                        </div>
                        <div class="objectives__select-inner">
                          <p v-if="chosenCommunicationObjective1 === 0">
                            To see the key attributes list you need to select
                            Communication objective.
                          </p>
                          <div class="flex flex-wrap" v-else>
                            <div
                              class="custom-checkbox"
                              v-for="item in activeAttributesList"
                              :key="item.atributeID"
                            >
                              <input
                                type="checkbox"
                                :value="item"
                                :id="item.attributeID"
                                v-model="chosenAttributes"
                                @change="setRobotValidation()"
                              />
                              <label
                                :for="item.attributeID"
                                :class="{ dlb: dlbStatus }"
                                >{{ item.AttributeLabel }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="objectives__map"
                      id="emotionalAttributesContent1"
                    >
                      <h3>
                        Communication Territory
                        <a-tooltip
                          placement="top"
                          overlayClassName="ant-tooltip--step-nav"
                        >
                          <template slot="title">
                            <span>
                              Emotional attributes are used to measure the
                              coherency of your packaging with your brand
                              strategy. How does your brand and packaging
                              deliver on key category drivers?
                              <span @click="showModal" class="cursor-pointer"
                                ><u>Click to learn more</u></span
                              >
                              about how Hotspex uses this powerful methodology.
                            </span>
                          </template>
                          <a-icon type="question-circle" />
                        </a-tooltip>
                      </h3>
                      <div class="objectives__map-inner">
                        <div class="objectives__map-image">
                          <!-- <img src="/images/mapExplorer.svg" alt="" /> -->
                          <Map
                            :selectedZone1="selectedZone1Name"
                            :selectedZone2="selectedZone2Name"
                            :selectedAttributes="chosenAttributes"
                          ></Map>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="objectives__select-inner"
                    v-if="chosenCommunicationObjective1 === 0"
                  >
                    <p class="mb-0 font-size-14">
                      Please select a Communication Objective above.
                    </p>
                  </div>
                </div>

                <div class="box-wrapper">
                  <h2 v-if="(dlbStatus && findCustomKey) || !dlbStatus">
                    Key messages
                  </h2>
                  <div v-if="(dlbStatus && findCustomKey) || !dlbStatus">
                    <div class="section-info">
                      <p v-if="!isPackSbdTestPresent">
                        Our standard pack test methodology uses predefined
                        category-specific statements which are delivered in
                        every report. The predefined statements for this
                        category can be seen
                        <a-tooltip
                          placement="top"
                          overlayClassName="ant-tooltip--step-nav"
                        >
                          <template slot="title">
                            <ul class="list-reset pl-10 list-type-disc">
                              <li
                                v-for="item in categoryStatements"
                                :key="item.id"
                              >
                                {{ item.CogniName }}
                              </li>
                            </ul> </template
                          ><span class="cursor-pointer"
                            ><u class="red">here</u></span
                          > </a-tooltip
                        >. In addition to these predefined statements, up to 5
                        custom statements can be added below:
                      </p>
                      <p v-else>
                        Please enter your key messages (cogni statements) you
                        want to evaluate for this study.
                        <span class="red"
                          >You have to enter a minimum of 4 and can add up to 12
                          messages</span
                        >.
                      </p>
                    </div>
                    <div class="ant-input-group-addon-small input-group">
                      <a-input
                        v-for="(option, index) in customKey"
                        placeholder="Add custom statement"
                        class="options-input mb-10"
                        :class="{ dlb: dlbStatus }"
                        :key="index"
                        v-model="option.optionText"
                        @pressEnter="addCustomKey(index)"
                      >
                        <a-button
                          slot="addonAfter"
                          v-if="
                            option.optionText === '' ||
                            (customKey[index + 1] === undefined &&
                              index + 1 < maxCustomKey)
                          "
                          @click="addCustomKey(index)"
                        >
                          Add
                        </a-button>
                        <a-button
                          v-else
                          slot="addonAfter"
                          class="ant-btn--delete"
                          @click="removeCustomKey(index)"
                        >
                          Delete
                        </a-button>
                      </a-input>
                    </div>

                    <a-button
                      class="mb-15"
                      type="primary"
                      @click="bulkAddModal = true"
                    >
                      Bulk Add
                    </a-button>
                    <p class="font-size-13 mb-0">
                      *Please ensure that the wording of your statements is in
                      the language of your study -
                      <strong>{{
                        projectSummary !== null
                          ? projectSummary.languageCode
                          : ""
                      }}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StepNaviBar
            v-if="!loading"
            :stepIndex="stepIndex"
            :nextDisable="enableNextStep"
            :conciergeAssistance="true"
            @nextHandler="nextStep"
            :prevDisable="false"
            @prevHandler="prevStep"
            prevBtnTooltip="Back to Stimuli"
            nextBtnTooltip="Go to Audience"
            stepsType="pack"
          />
        </div>
      </section>
    </div>
    <CompletionProgress :checkList="quizs" v-if="!loading"></CompletionProgress>

    <a-modal
      v-model="personaPreviewModal"
      width="90%"
      height="90vh"
      centered
      :footer="null"
      :header="false"
      wrapClassName="ant-modal--persona-iframe"
    >
      <iframe
        src="https://hotspex.com/persona/"
        width="100%"
        height="100%"
        frameborder="0"
      >
      </iframe>
    </a-modal>

    <a-modal
      title="Bulk Add"
      :visible="bulkAddModal"
      @ok="processKeyMessages"
      @cancel="bulkAddModal = false"
      cancelText="Cancel"
      okText="Add"
      :width="1100"
    >
      <div class="input-group position-r">
        <label
          >Please copy and paste your full list with up to 12 key messages
          below. Make sure you paste them in a vertical list format, with one
          message per row.</label
        >
        <a-textarea v-model="keyMessagesText" :auto-size="{ minRows: 8 }" />
        <div v-if="errorMessage" class="validation">{{ errorMessage }}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import Loader from "@/components/general/Loader.vue";
import Map from "@/components/general/Map.vue";
import CompletionProgress from "@/components/general/CompletionProgress.vue";
import _ from "lodash";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import IconBase from "@/components/general/IconBase.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import IconBulb from "@/components/icons/IconBulb.vue";

export default {
  name: "CommunicationObjective",
  components: {
    ProgressBar,
    StepNaviBar,
    Loader,
    CompletionProgress,
    Map,
    IconBase,
    IconTrash,
    IconBulb,
  },
  mixins: [surveyPriceMixin],
  data() {
    return {
      stepIndex: 3,
      langObj: {},
      client: {},
      quizs: [],
      attributes: [],
      communicationObjectives: [],
      surveyID: 0,
      personaPreviewModal: false,
      loading: false,
      selectedStatus: 0,
      chosenCommunicationObjective1: 0,
      chosenCommunicationObjective2: 0,
      chosenZone1Id: null,
      chosenZone2Id: null,
      showCommunicationObjective2: false,
      chosenAttributes: [],
      editableSurvey: undefined,
      customKey: [{ optionText: "" }],
      maxCustomKey: 5,
      projectSummary: null,
      categoryStatements: [],
      user: null,
      selectedZone1Name: "",
      selectedZone2Name: "",
      boxHeight: 0,
      selectedCO1Name: null,
      selectedCO2Name: null,
      surveyType: null,
      breadcrumbTitle: "Pack Test",
      isPackSbdTestPresent: false,
      bulkAddModal: false,
      errorMessage: "",
      keyMessagesText: "",
      surveyInfo: null,
    };
  },
  computed: {
    enableNextStep() {
      return this.quizs.some((quiz) => quiz.completed !== true);
    },
    activeAttributesList() {
      return this.attributes.filter((item) =>
        [this.chosenZone1Id, this.chosenZone2Id].includes(item.zoneID)
      );
    },
    filteredCommunicationObjectives() {
      let chosenCommunicationObjective1 = this.communicationObjectives.find(
        (item) => {
          return (
            item.CommunicationObjectiveID === this.chosenCommunicationObjective1
          );
        }
      );
      let excludedCommunicationObjective = [
        chosenCommunicationObjective1.CommunicationObjectiveID,
      ];
      if (chosenCommunicationObjective1 !== undefined) {
        _.forEach(chosenCommunicationObjective1.Excluded, function (item) {
          excludedCommunicationObjective.push(item.ExcludedID);
        });
      }

      return this.communicationObjectives.filter(
        (item) =>
          !excludedCommunicationObjective.includes(
            item.CommunicationObjectiveID
          )
      );
    },
    dlbStatus() {
      return this.surveyType === "packtestextend";
    },
    findCustomKey() {
      return this.customKey.some((item) => item.optionText !== "");
    },
  },
  methods: {
    prevStep() {
      this.$router.push({
        name: this.isPackSbdTestPresent ? "stimuli-sbd" : "stimuli",
        params: { id: this.surveyID },
      });
    },
    nextStep() {
      this.save();
    },
    updateQuiz() {
      if (
        this.isPackSbdTestPresent &&
        this.surveyInfo &&
        this.surveyInfo.studyType === "SBD"
      ) {
        this.quizs = [
          {
            quizName: this.langObj["communication-objective"].robot3,
            completed: false,
          },
        ];
      } else if (
        this.isPackSbdTestPresent &&
        this.surveyInfo &&
        this.surveyInfo.studyType !== "SBD"
      ) {
        this.quizs = [
          {
            quizName: this.langObj["communication-objective"].robot1,
            completed: false,
          },
          {
            quizName: this.langObj["communication-objective"].robot2,
            completed: false,
          },
          {
            quizName: this.langObj["communication-objective"].robot3,
            completed: false,
          },
        ];
      } else {
        this.quizs = [
          {
            quizName: this.langObj["communication-objective"].robot1,
            completed: false,
          },
          {
            quizName: this.langObj["communication-objective"].robot2,
            completed: false,
          },
        ];
      }
    },
    showModal() {
      this.personaPreviewModal = true;
    },
    addCommunicationObjective() {
      let _this = this;
      this.$confirm({
        title: "Add second communication objective",
        content: () => (
          <div>
            Adding a second objective significantly increases the cost of the
            study. Do you want to add it?
          </div>
        ),
        onOk() {
          _this.showCommunicationObjective2 = true;
        },
        okText: "Yes",
        onCancel() {},
      });
    },
    removeCommunicationObjective() {
      let _this = this;
      this.$confirm({
        title: "Remove second communication objective",
        content: () => (
          <div>
            Are you sure you want to delete second communication objective?
          </div>
        ),
        onOk() {
          _this.chosenCommunicationObjective2 = 0;
          _this.showCommunicationObjective2 = false;
          _this.chosenZone2Id = null;
          _this.selectedCO2Name = null;
          _this.selectedZone2Name = "";
          _this.clearChosenAttributes();
          _this.priceCalculation();
        },
        okText: "Yes",
        onCancel() {},
      });
    },
    selectObjective1(data) {
      this.chosenCommunicationObjective1 = data;
      this.setRobotValidation();
      this.chosenZone1Id = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective1
        );
      }).CommunicationZones;
      this.selectedZone1Name = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective1
        );
      }).CommunicationZonesNames;
      this.selectedCO1Name = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective1
        );
      }).CommunicationObjectiveName;
      this.chosenCommunicationObjective2 = 0;
      this.chosenZone2Id = null;
      this.clearChosenAttributes();
      setTimeout(() => {
        this.setHeightToEmotionalAttributes();
      }, 0);
      this.priceCalculation();
    },
    selectObjective2(data) {
      this.chosenCommunicationObjective2 = data;
      this.chosenZone2Id = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective2
        );
      }).CommunicationZones;
      this.selectedZone2Name = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective2
        );
      }).CommunicationZonesNames;
      this.selectedCO2Name = this.communicationObjectives.find((item) => {
        return (
          item.CommunicationObjectiveID === this.chosenCommunicationObjective2
        );
      }).CommunicationObjectiveName;
      this.clearChosenAttributes();
      setTimeout(() => {
        this.setHeightToEmotionalAttributes();
      }, 0);
      this.priceCalculation();
    },
    scrollToEmotionalAttributes() {
      let emotionalAttributesOffset = document.getElementById(
        "emotionalAttributes"
      ).offsetTop;
      document.getElementById("objectivesWrapper").scrollTo({
        top: emotionalAttributesOffset - 180,
        behavior: "smooth",
      });
    },
    setHeightToEmotionalAttributes() {
      let h = document.getElementById(
        "emotionalAttributesContent"
      ).offsetHeight;
      let h1 = document.getElementById(
        "emotionalAttributesContent1"
      ).offsetHeight;
      this.boxHeight = h + h1 + 25;
    },
    setRobotValidation() {
      if (
        !this.surveyInfo.studyType ||
        (this.surveyInfo.studyType !== "SBD" &&
          this.surveyInfo.studyType !== "Pack")
      ) {
        if (
          this.chosenAttributes.length > 2 &&
          this.chosenAttributes.length < 10
        ) {
          this.quizs[1].completed = true;
        } else {
          this.quizs[1].completed = false;
        }

        if (this.chosenCommunicationObjective1 !== 0) {
          this.quizs[0].completed = true;
        } else {
          this.quizs[0].completed = false;
        }
      } else if (
        this.surveyInfo.studyType &&
        this.surveyInfo.studyType === "Pack"
      ) {
        if (
          this.chosenAttributes.length > 2 &&
          this.chosenAttributes.length < 10
        ) {
          this.quizs[1].completed = true;
        } else {
          this.quizs[1].completed = false;
        }

        if (this.chosenCommunicationObjective1 !== 0) {
          this.quizs[0].completed = true;
        } else {
          this.quizs[0].completed = false;
        }

        if (
          this.customKey.filter((message) => message.optionText !== "")
            .length >= 4 &&
          this.customKey.filter((message) => message.optionText !== "")
            .length <= 12
        ) {
          this.quizs[2].completed = true;
        } else {
          this.quizs[2].completed = false;
        }
      } else if (
        this.surveyInfo.studyType &&
        this.surveyInfo.studyType === "SBD"
      ) {
        if (
          this.customKey.filter((message) => message.optionText !== "")
            .length >= 4 &&
          this.customKey.filter((message) => message.optionText !== "")
            .length <= 12
        ) {
          this.quizs[0].completed = true;
        } else {
          this.quizs[0].completed = false;
        }
      }
    },
    setCommunicationObjectives() {
      const getCommunicationObjectives = new Promise((resolve) => {
        wsUtils.GetCommunicationObjectives(resolve);
      });
      getCommunicationObjectives.then((data) => {
        this.communicationObjectives = data;
      });
    },
    setAttributes() {
      const personaListPromise = new Promise((resolve) => {
        wsUtils.getPersonaAttributes(resolve);
      });
      personaListPromise.then((data) => {
        this.attributes = data;
        this.setEditMode();
        setTimeout(() => {
          this.loading = false;
        }, 200);
      });
    },
    setEditMode() {
      if (this.editableSurvey !== undefined) {
        this.setCOEdit();
        this.setKeyMessagesEdit();
      }
    },
    setCOEdit() {
      if (
        this.editableSurvey.SurveyConfig.CommunicationObjective1 &&
        this.editableSurvey.SurveyConfig.CommunicationObjective1 !== -1 &&
        this.editableSurvey.SurveyConfig.CommunicationObjective1 !== 0
      ) {
        this.boxHeight = "auto";
        this.chosenCommunicationObjective1 =
          this.editableSurvey.SurveyConfig.CommunicationObjective1;
        this.chosenCommunicationObjective2 =
          this.editableSurvey.SurveyConfig.CommunicationObjective2;

        this.chosenZone1Id = this.communicationObjectives.find((item) => {
          return (
            item.CommunicationObjectiveID === this.chosenCommunicationObjective1
          );
        }).CommunicationZones;

        this.selectedZone1Name = this.communicationObjectives.find((item) => {
          return (
            item.CommunicationObjectiveID === this.chosenCommunicationObjective1
          );
        }).CommunicationZonesNames;

        this.selectedCO1Name = this.communicationObjectives.find((item) => {
          return (
            item.CommunicationObjectiveID === this.chosenCommunicationObjective1
          );
        }).CommunicationObjectiveName;

        let zone2 = this.communicationObjectives.find((item) => {
          return (
            item.CommunicationObjectiveID === this.chosenCommunicationObjective2
          );
        });

        if (zone2 !== undefined) {
          this.chosenZone2Id = zone2.CommunicationZones;
          this.selectedZone2Name = zone2.CommunicationZonesNames;
          this.selectedCO2Name = zone2.CommunicationObjectiveName;
        }

        _.forEach(this.editableSurvey.AttributeDetails, (item) => {
          let attribute = this.attributes.find((attr) => {
            return attr.attributeID === item.attributeID;
          });
          this.chosenAttributes.push(attribute);
        });

        this.showCommunicationObjective2 =
          this.chosenCommunicationObjective2 !== 0 ? true : false;
        this.setRobotValidation();
      }
    },
    setKeyMessagesEdit() {
      this.customKey = [{ optionText: "" }];
      if (
        this.editableSurvey &&
        this.editableSurvey.keyMessages &&
        this.editableSurvey.keyMessages.length > 0
      ) {
        this.customKey = [];
        _.forEach(this.editableSurvey.keyMessages, (item) => {
          this.customKey.push({ optionText: item });
        });
        this.setRobotValidation();
      }
    },
    clearChosenAttributes() {
      this.chosenAttributes = this.chosenAttributes.filter((item) =>
        [this.chosenZone1Id, this.chosenZone2Id].includes(item.zoneID)
      );
      this.setRobotValidation();
    },
    save() {
      let getKeyMessage = this.customKey
        .filter((item) => item.optionText !== "")
        .map((item) => {
          return item.optionText;
        });

      const selectIDs = this.chosenAttributes.map((attr) => attr.attributeID);
      const savePackTestPersonaAttributes = new Promise((resolve) => {
        wsUtils.SavePackTestPersonaAttributes(
          {
            surveyID: this.surveyID,
            attributeIDs: selectIDs.toString(),
            CommunicationObjective1: this.chosenCommunicationObjective1,
            CommunicationObjective2: this.chosenCommunicationObjective2,
            keaMessage: getKeyMessage,
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });
      savePackTestPersonaAttributes.then(() => {
        jsUtils.setSurveysApiTrigger(true);
        this.setSurveySummaryData();
        let nextStep;

        if (!this.isPackSbdTestPresent) {
          nextStep =
            this.user.SkinIdentifier !== "pg" && !this.dlbStatus
              ? "custom-questions"
              : "pack-test-launch";
        } else {
          nextStep = "pack-sbd-category-inputs";
        }

        this.$router.push({
          name: nextStep,
          params: { id: this.surveyID },
        });
      });
    },
    resetData() {
      this.chosenCommunicationObjective1 = 0;
      this.chosenCommunicationObjective2 = 0;
      this.chosenZone1Id = null;
      this.chosenZone2Id = null;
      this.chosenAttributes = [];
      this.showCommunicationObjective2 = false;
      this.boxHeight = 0;
      this.selectedCO2Name = null;
    },
    setSurveySummaryData() {
      let communicationObjectives = {
        chosenCommunicationObjective1:
          this.communicationObjectives.find((item) => {
            return (
              item.CommunicationObjectiveID ===
              this.chosenCommunicationObjective1
            );
          }) || -1,
        chosenCommunicationObjective2:
          this.communicationObjectives.find((item) => {
            return (
              item.CommunicationObjectiveID ===
              this.chosenCommunicationObjective2
            );
          }) || -1,
      };
      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({
        ...currentSummaryData,
        ...communicationObjectives,
      });
    },
    addCustomKey(index) {
      if (this.customKey[index].optionText !== "") {
        if (this.customKey.length < this.maxCustomKey) {
          this.customKey.push({ optionText: "" });
          setTimeout(() => {
            const optionInputs = document.querySelectorAll(
              ".options-input input"
            );
            optionInputs[this.customKey.length - 1].focus();
            this.setRobotValidation();
          }, 200);
        }
      }
    },
    removeCustomKey(index) {
      if (this.customKey.length === 1) {
        this.customKey[0] = { optionText: "" };
      } else {
        this.customKey.splice(index, 1);
      }
      this.setRobotValidation();
    },
    defaultCategoryStatements() {
      const getCategoryStatements = new Promise((resolve) => {
        wsUtils.GetCategoryStatements(
          {
            categoryID:
              this.projectSummary.activeShelf.categoryId !== undefined
                ? this.projectSummary.activeShelf.categoryId
                : this.editableSurvey.SurveyConfig.CategoryId,
          },
          resolve
        );
      });
      getCategoryStatements.then((data) => {
        this.categoryStatements = data;
      });
    },
    priceCalculation() {
      let comObjects;

      if (
        this.chosenCommunicationObjective1 !== 0 &&
        this.chosenCommunicationObjective1 !== -1
      ) {
        comObjects = 1;
      }
      if (
        this.chosenCommunicationObjective1 !== 0 &&
        this.chosenCommunicationObjective1 !== -1 &&
        this.chosenCommunicationObjective2 !== 0 &&
        this.chosenCommunicationObjective2 !== -1
      ) {
        comObjects = 2;
      }

      if (comObjects !== 0) {
        let currentPackTestPriceData = jsUtils.getPackTestPrice();
        jsUtils.setPackTestPrice({
          ...currentPackTestPriceData,
          comObjects: comObjects,
        });
        this.getPackTestTotalPrice();
      }
    },
    updatePackTestUI() {
      if (this.isPackSbdTestPresent) {
        this.breadcrumbTitle = "Pack SBD Test";
        this.maxCustomKey = 12;
      }
    },
    checkUrlForPackSbdTest() {
      const currentPath = this.$route.path;
      this.isPackSbdTestPresent = currentPath.includes("pack-sbd-test");
    },
    processKeyMessages() {
      this.errorMessage = "";
      const messages = this.keyMessagesText
        .split("\n")
        .map((message) => {
          return { optionText: message.trim() };
        })
        .filter((message) => message.optionText !== "");
      const customKeyLength = this.customKey.filter(
        (message) => message.optionText !== ""
      ).length;

      if (messages.length + customKeyLength > 12) {
        this.errorMessage = `Woops you've added too many key messages. Please remove ${
          messages.length + customKeyLength - 12
        }  messages to continue.`;
      } else {
        this.customKey = this.customKey.filter(
          (message) => message.optionText !== ""
        );
        this.customKey.push(...messages);
        this.bulkAddModal = false;
        this.keyMessagesText = "";
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.resetData();
    next();
  },
  activated() {
    this.surveyInfo = jsUtils.getSurveySummaryData();
    this.surveyType = jsUtils.readCookie("survey-type");
    this.user = jsUtils.getUserInfo();
    this.loading = true;
    this.client = jsUtils.getCurClient();
    this.surveyID = this.$route.params.id;
    this.projectSummary = jsUtils.getSurveySummaryData();
    this.editableSurvey = jsUtils.getEditableSurvey();
    this.checkUrlForPackSbdTest();
    this.updateQuiz();
    this.setAttributes();
    this.setCommunicationObjectives();
    this.defaultCategoryStatements();
    this.updatePackTestUI();
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
  },
  watch: {
    // enableNextStep(newVal) {
    //   if (!newVal) {
    //     this.quizs[0].completed = true;
    //   } else {
    //     this.quizs[0].completed = false;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.objectives {
  padding: 0 0 113px;
  text-align: left;
  .authorBtnSection {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
  }
  &__wrapper {
    height: calc(100vh - 168px);
    overflow: auto;
    padding: 0 20px;
  }
  &__inner {
    max-width: 970px;
    margin: 0 auto;
    padding: 50px 0 80px;
    min-height: 100%;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 34px;
  }
  &__left-col {
    .section-info {
      margin-bottom: 32px;
    }
    h2 {
      color: #1c1c1c;
      font-size: 20px;
      line-height: normal;
      font-weight: 500;
      letter-spacing: -0.4px;
      margin-bottom: 28px;
    }
    .box-wrapper {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__info {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 20px;
    @media screen and (max-width: 980px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  &__form {
    border: 1px solid #d2d2d2;
    padding: 30px 20px;
    border-radius: 8px;
    margin-bottom: 30px;

    &--key {
      p {
        font-size: 14px;
      }
    }

    &--overflow-hidden {
      overflow: hidden;
    }
  }
  &__form-attributes {
    transition: 1.2s ease all;
  }
  &__select {
    max-width: 410px;
    margin-bottom: 10px;
    position: relative;
    padding-top: 10px;
    &--full {
      max-width: 100%;
    }
    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    ::v-deep .ant-select {
      min-width: 200px;
      .ant-select-selection--single {
        height: 40px;
        border-color: #d4d9dd;
        border-radius: 4px;
        .ant-select-selection__rendered {
          line-height: 38px;
        }
      }
    }
    > label {
      font-size: 14px;
      margin-bottom: 3px;
      color: #2c3e50;
      display: block;
      font-weight: 500;
    }
  }
  &__select-inner {
    display: flex;
    flex-wrap: wrap;
    .custom-checkbox {
      margin: 7px;
      input:checked + label {
        border: 1px solid var(--red);
        box-shadow: none;
        color: var(--red);
      }
      label {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.32px;
        border-radius: 20px;
        border: 1px solid #1c1c1c;
        padding: 4px 10px 6px;
      }
    }
  }
  &__select-remove {
    cursor: pointer;
    color: transparent;
  }
  &__add-more {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 20px;
    margin: 42px auto 0;
    padding: 0 10px;

    span {
      margin-left: 14px;
    }
  }
  &__map {
    margin-top: 22px;
    h3 {
      font-size: 16px;
      margin-bottom: 30px;
      i {
        margin-left: 8px;
      }
    }
    p {
      font-size: 13px;
      line-height: 15px;
      margin: 10px 0 0;
      transition: opacity 0.3s ease;
      opacity: 0;
    }
  }
  &__map-image {
    transition: all 0.3s ease;
    position: relative;
    .map {
      max-width: 396px;
      margin: 0 auto;
    }
    .anticon {
      position: absolute;
      bottom: 7px;
      right: 4px;
      ::v-deep svg {
        width: 30px;
        height: 25px;
        color: #6a6969;
      }
    }
  }
  &__key-list {
    max-width: 410px;
    ::v-deep .ant-input {
      height: 38px;
    }
    ::v-deep .ant-input-group-addon .ant-btn {
      height: 36px;
    }
  }
}
.custom-checkbox {
  margin: 4px;
  &:last-child {
    margin-bottom: 0;
  }
  label {
    position: relative;
    user-select: none;
    cursor: pointer;
    font-size: 13px;
    display: inline-flex;
    padding: 3px 6px;
    border-radius: 7px;
    border: 1px solid #d4d9dd;
    background: #fff;
    transition: all 0.2s ease;
  }
  input {
    display: none;
    &:checked {
      + label {
        border-color: #369be1;
        outline: 2px;
        box-shadow: 0px 0px 0px 1px #369be1;
      }
    }
  }
}
.input-group .options-input .ant-input-group-addon .ant-btn {
  border: 0;
  height: 48px;
}
</style>

<style lang="scss">
.ant-modal {
  pointer-events: auto;
  &--persona-iframe {
    z-index: 9999;
    .ant-modal-body {
      height: 90vh;
      padding: 0px;
    }
    .ant-modal-close {
      right: 20px;
      color: #ffffff;
    }
  }
}
.ant-tooltip-inner > span > u {
  cursor: pointer;
}

.ant-tooltip {
  &--delete-objective {
    font-size: 11px !important;
    .ant-tooltip-inner {
      padding: 4px 8px 1px;
      min-height: auto;
      background-color: #2c3e50;
      padding: 0 6px;
    }
  }
}
</style>
